<template>
    <div>
        <div v-if="type === 0">
            <v-text-field
                    :disabled="disabled"
                    :prefix="prefix"
                    :suffix="postfix"
                    outlined
                    v-model="value"
                    @change="change"
            ></v-text-field>
        </div>
        <v-switch
                :disabled="disabled"
                v-if="type === 1 && options.length < 3"
                v-model="value"
                :label="`${options[(value === true ? 0 : 1)].text}`"
                @change="change"
        ></v-switch>
<!--        <template v-if="maxText">-->
<!--            {{ getText(text) }} <span class="show" v-on:click="showAndHideText"></span>-->
<!--        </template>-->
<!--        <template v-else>-->
<!--            {{ getText(text) }}-->
<!--        </template>-->
    </div>
</template>

<script>
    // eslint-disable-next-line no-unused-vars
    import {parseISO, isValid, parse, format} from "date-fns";

    export default {
        name: "AppDocumentItem",
        data: () => ({
            show: true,
            maxText: false,
            slice: 30,
            isDate: false,
            options: [],
            type: 0,
            value: null,
            postfix: null,
            prefix: null
        }),
        props: {
            formItem: [Object],
            disabled: {
                type: Boolean,
                default: false
            },
            first: {
                type: Boolean,
                default: true
            }
        },
        created() {
            if (this.first) {
                this.getFirstAndToValue(this.formItem.values1, this.formItem.type1, this.formItem.postfix1, this.formItem.prefix1, this.formItem.value1)

            } else {
                this.getFirstAndToValue(this.formItem.values2, this.formItem.type2, this.formItem.postfix2, this.formItem.prefix2, this.formItem.value2)
            }

            // if (typeof this.text === 'string') {
            //     this.maxText = this.text.length > this.slice;
            //     let date = parseISO(this.text);
            //     // console.log(this.text);
            //     // console.log(date);
            //     if (isValid(date)) {
            //         this.isDate = true;
            //         console.log();
            //         // this.text = format(date, "Y")
            //     }
            // }
        },
        methods: {
            change() {
                if (this.type === 1 && this.options.length < 3)
                    this.$emit('newValue', {v: this.options[(this.value === true ? 0 : 1)].text, notSubmit: false})
                if (this.type === 0)
                    this.$emit('newValue', {v: this.value, notSubmit: false})
            },
            getFirstAndToValue(values, type, postfix, prefix, value) {
                if (type === 1) {
                    this.parseStringToArray(values)
                    if (value != null) {
                        let key = this.options.findIndex(v => v.text === value);
                        this.value = key === -1 ? true : (key !== 1)
                    }
                    else {
                        this.value = false;
                        this.$emit('newValue', {v: this.options[(this.value === true ? 0 : 1)].text, notSubmit: true})
                    }

                }
                if (type === 0) {
                    if (value != null) {
                        this.value = value
                    }
                    else {
                        this.value = '';
                        this.$emit('newValue', {v: this.value, notSubmit: true})
                    }

                }
                this.postfix = postfix;
                this.prefix = prefix;
                this.type = type;
            },
            parseStringToArray(value) {
                // let newObject = {};
                let arrayValue =  value.split('|')
                // arrayValue.forEach(item => {
                //     let objectItem = item.split('=')
                //     newObject[objectItem[0]] = objectItem[1];
                // })
                // eslint-disable-next-line no-unused-vars
                for (const [key, value] of Object.entries(arrayValue)) {
                    this.options.push({
                        text: value,
                        value: value,
                    });
                }
            },
        }
    }
</script>

<style scoped>
    .show {
        color: blue;
        cursor: pointer;
    }
</style>