<template>
  <v-row style="margin: 0">
    <v-col cols="3" style=" border-right: 1px solid black; ">
      <div :class="'row--border col-form-20p pa-3 b-btn'">
        <div class="row">
          <v-col>
            <!--                    <app-document-item :first="{type: 1}" v-on:newValue="(v) => change(v, index, false)"-->
            <!--                                       :form-item="formItem"></app-document-item>-->
            <v-switch
                    v-model="formItem.isApplied"
                    v-on:change="(v) => isAppliedChange(v, index)"
            ></v-switch>
          </v-col>
        </div>
      </div>
    </v-col>
    <v-col cols="9">
      <div :class="'row--border col-form-25p pa-3 b-btn'">
        <div class="row">
          <v-col v-if="formItem.type1 != 9">
            <app-document-item :first="true"
                               v-on:newValue="(data) => change(data.v, index, true, data.notSubmit)"
                               :disabled="formItem.isApplied == false"
                               :form-item="formItem"></app-document-item>
          </v-col>
          <v-col v-if="formItem.type2 != 9">
            <app-document-item :first="false"
                               v-on:newValue="(data) => change(data.v, index, false, data.notSubmit)"
                               :disabled="formItem.isApplied == false"
                               :form-item="formItem"></app-document-item>
          </v-col>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import AppDocumentItem from "./AppDocumentItem";

  export default {
    name: "AppGroupDocumentItem",
    props: {
      formItem: [Object],
      index: [String, Number],
    },
    data: () => ({
      switch: false,
    }),
    created() {
      this.switch = this.formItem.isApplied == 1;
    },
    components: {
      AppDocumentItem,
    },
    methods: {
      isAppliedChange(v, index) {
        this.$emit('appliedChangeEvent', {v, index})
      },
      change(val, index, first, notSubmit) {
        this.$emit('newValue', {val, index, first, notSubmit})
      },
    }
  }
</script>

<style scoped>

</style>